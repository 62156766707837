import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

import Layout from "../components/layout";
import styles from "./contact.module.css";

export default function Contact() {
  return (
    <Layout
      seoTitle="Contact Us"
      seoDescription="Looking for financial consulting or accounting. Look no further, get in touch with us now."
    >
      <div className={styles.container}>
        <h1 className={styles.pageTitle}>Contact Us</h1>
        <p className={styles.contactPara}>
          You can either use the form or the contact information. We'll get back
          in touch with you as soon as possible
        </p>
        <div className={styles.contactOptions}>
          <ul className={styles.contactList}>
            <li className={styles.contactListItem}>
              <FontAwesomeIcon icon={faPhoneAlt} /> +91-9681161032
            </li>
            <li className={styles.contactListItem}>
              <FontAwesomeIcon icon={faPhoneAlt} /> +91-8100826251
            </li>
            <li className={styles.contactListItem}>
              <FontAwesomeIcon icon={faEnvelope} /> info@rlrbose.co.in
            </li>
            <li className={styles.contactListItem}>
              RA-452, Room No. 4,<br></br>Ground Floor, Nabapally,<br></br>Salt
              Lake, Sector – IV,<br></br> Kolkata, West Bengal.<br></br>{" "}
              Pincode: 700098
            </li>
          </ul>

          <form
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="contact"
            className={styles.contactForm}
            id="contact-us-form"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <label className={styles.nameLabel} htmlFor="name">
              Name
            </label>
            <input
              className={styles.nameInput}
              type="text"
              name="name"
              id="name"
            />
            <label className={styles.emailLabel} htmlFor="email">
              Email
            </label>
            <input
              className={styles.emailInput}
              type="email"
              name="email"
              id="email"
            />
            <label className={styles.messageLabel} htmlFor="message">
              Message
            </label>
            <textarea
              className={styles.messageInput}
              type="text"
              name="message"
              id="message"
            />
            <button className={styles.formButton}>Send</button>
          </form>
        </div>
      </div>
    </Layout>
  );
}
